import { createReducer } from '@reduxjs/toolkit';
import { firstRendersSetAction, firstRendersSetErrorAction, firstRendersSetLoadingAction } from 'store/actions/first-renders-actions';
import IRenders from 'types/IRenders';

type firstRendersState = {
  contracts: IRenders[];
  loading: boolean;
  error: boolean;
};

const initialState: firstRendersState = {
  contracts: [],
  loading: false,
  error: false,
};

const firstRendersReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(firstRendersSetAction, (state, action) => ({ ...state, contracts: action.payload }))
    .addCase(firstRendersSetErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(firstRendersSetLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    })),
);

export default firstRendersReducer;
