import { getApi } from 'api';
import axios, { AxiosResponse } from 'axios';
import IClientEmailSendingResponse from 'types/responses/IClientEmailSendingsResponse';
import IFleetManagerEmailSendingsResponse from 'types/responses/IFleetManagerEmailSendingsResponse';
import log from 'utils/log';

export const getAllClientEmailSendings = async (): Promise<IClientEmailSendingResponse> => {
  try {
    const response: AxiosResponse<IClientEmailSendingResponse> = await getApi().get('api/emailSendings/client');
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};

export const createClientEmailSending = async (): Promise<boolean> => {
  try 
  {
    const response: AxiosResponse = await getApi().post('api/emailSendings/client');
    return true;
  } catch(err) {
    if(!axios.isAxiosError(err)) {
      log(err);
    }
    return false;
  }
}

export const getAllFleetManagerEmailSendings = async (): Promise<IClientEmailSendingResponse> => {
  try {
    const response: AxiosResponse<IFleetManagerEmailSendingsResponse> = await getApi().get('api/emailSendings/fleetManager');
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};

export const createFleetManagerEmailSending = async (): Promise<boolean> => {
  try 
  {
    const response: AxiosResponse = await getApi().post('api/emailSendings/fleetManager');
    return true;
  } catch(err) {
    if(!axios.isAxiosError(err)) {
      log(err);
    }
    return false;
  }
}