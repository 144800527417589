import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  rendersContainerFlex: {
    display: 'flex',
  },
  rendersContainerCard: {
    width: '500px',
    marginBottom: '30px',
    display: 'flex',
  },
  rendersContainerCardIcon: {
    width: '150px',
    height: '150px',
    textAlign: 'center',
    borderRadius: '0',
    color: 'white',
    backgroundColor: '#7e57c2',
  },
  rendersContainerCardValue: {
    width: '350px',
    height: '150px',
    fontSize: '30px',
    borderRadius: '0',
    color: 'white',
    backgroundColor: '#9778ce',
    cursor: 'pointer',
  },
  iconLargeWhite: {
    marginTop: '60px',
    transform: 'scale(2.5)',
    color: 'white',
  },
});

export default useStyles;
