import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import AppContainer from 'components/app-container';
import Dashboard from 'modules/dashboard';
import LeasingContracts from 'modules/leasing-contracts';
import Logout from 'modules/logout';
import DriverFiles from 'modules/driver-files';
import FirstRenders from 'modules/first-renders';
import RepeatRenders from 'modules/repeat-renders';
import EmailBounces from 'modules/email-bounces';
import LeasingCompanies from 'modules/leasing-companies';
import ClientEmailSeding from 'modules/client-email-sending';
import FleetManagerEmailSending from 'modules/fleet-manager-email-sending';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <AppContainer>
        <Switch>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/leasing-contracts">
            <LeasingContracts />
          </Route>
          <Route path="/leasing-companies">
            <LeasingCompanies />
          </Route>
          <Route path="/driver-files">
            <DriverFiles />
          </Route>
          <Route path="/first-renders">
            <FirstRenders />
          </Route>
          <Route path="/repeat-renders">
            <RepeatRenders />
          </Route>
          <Route path="/email-bounces">
            <EmailBounces />
          </Route>
          <Route path="/client-email-sending">
            <ClientEmailSeding />
          </Route>
          <Route path="/fleet-manager-email-sending">
            <FleetManagerEmailSending />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/">
            <Redirect to="/dashboard" />
          </Route>
        </Switch>
      </AppContainer>
    </BrowserRouter>
  );
};

export default AppRouter;
