import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  { field: 'id', hide: true, disableExport: true },
  {
    field: 'leaseCompany',
    headerName: 'Leasebedrijf',
    width: 300,
  },
  {
    field: 'clientNumber',
    headerName: 'Klantnummer',
    width: 200,
  },
  {
    field: 'licensePlate',
    headerName: 'Kenteken',
    sortable: false,
    width: 170,
  },
  {
    field: 'chassisNumber',
    headerName: 'Bestuurdernummer',
    width: 250,
    sortable: false,
  },
  {
    field: 'leaseContractNumber',
    headerName: 'Leasecontractnr',
    width: 250,
  },
  {
    field: 'insurancePolicyNumber',
    headerName: 'Verzekeringpolisnr',
    width: 300,
  },
  {
    field: 'renderCount',
    headerName: 'RenderCount',
    width: 200,
  },
];
