import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
  },
  mainWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 100,
  },
  mainContainer: {
    width: '100%',
    maxWidth: 500,
  },
});

export default useStyles;
