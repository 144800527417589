import axios from 'axios';
import { getConfig } from 'config';
import jwtDecode from 'jwt-decode';
import store from 'store';
import { authStateSelector, authTokenSelector } from 'store/selectors/auth-selectors';
import { doAuthLogoutAsyncAction } from 'store/actions/auth-actions';

let instance = axios.create({});
const { CancelToken } = axios;

export const initApi = () => {
  instance = axios.create({
    baseURL: getConfig('API_BASE_URL'),
  });

  instance.interceptors.request.use((config) => {
    const newConfig = { ...config };
    if (authStateSelector(store.getState())) {
      const token = authTokenSelector(store.getState());
      newConfig.headers.Authorization = `Bearer ${token}`;

      const decodedToken = jwtDecode(token);
      const expirationTime = (decodedToken as any).exp * 1000;
      if (Date.now() >= expirationTime) {
        store.dispatch(doAuthLogoutAsyncAction());
        newConfig.cancelToken = new CancelToken((cancel) => cancel('Expired token'));
      }
    }

    return newConfig;
  });
};

export const getApi = () => instance;
