import { Box } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { leasingContractsFetchAsyncAction } from 'store/actions/leasing-contracts-actions';
import { leasingContractsLoadingSelector, leasingContractsSelector } from 'store/selectors/leasing-contracts-selectors';
import { columns } from './constants';

const LeasingContracts = () => {
  const dispatch = useAppDispatch();
  const rows = useAppSelector(leasingContractsSelector);
  const loading = useAppSelector(leasingContractsLoadingSelector);
  useEffect(() => {
    dispatch(leasingContractsFetchAsyncAction());
  }, []);

  const renderTable = () => {
    return (
      <Box component="div" height="670px" width="100%" mb={2}>
        <DataGrid loading={loading} rows={rows} columns={columns} pageSize={10} disableSelectionOnClick disableColumnSelector />
      </Box>
    );
  };
  return (
    <Box component="div">
      <Box component="h2" mt={0}>
        Leasing Contracts
      </Box>
      <Box component="div" height="670px" width="100%" mb={2}>
        {renderTable()}
      </Box>
    </Box>
  );
};

export default LeasingContracts;
