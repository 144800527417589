/* eslint-disable react/jsx-props-no-spreading */
import { Card, CardHeader } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TAB_ROUTE__HOME_NAME } from 'translations/constants';
import useStyles from './styles';

const Register = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div className={classes.mainWrapper}>
      <Card className={classes.mainContainer}>
        <CardHeader title={t(TAB_ROUTE__HOME_NAME)} />
      </Card>
    </div>
  );
};

export default Register;
