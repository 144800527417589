export const LOGIN__EMAIL_LABEL = '[LOGIN] EMAIL_LABEL';
export const LOGIN__PASSWORD_LABEL = '[LOGIN] PASSWORD_LABEL';
export const LOGIN__CTA = '[LOGIN] CTA';
export const LOGIN__ERROR = '[LOGIN] ERROR';

export const DRAWER__PROFILE = '[DRAWER] PROFILE';
export const DRAWER__GENERAL_SETTINGS = '[DRAWER] GENERAL_SETTINGS';
export const DRAWER__LOGOUT = '[DRAWER] LOGOUT';

export const LOGOUT_ALERT__CANCEL_BUTTON = '[LOGOUT_ALERT] CANCEL_BUTTON';
export const LOGOUT_ALERT__CONFIRM_BUTTON = '[LOGOUT_ALERT] CONFIRM_BUTTON';
export const LOGOUT_ALERT__MESSAGE = '[LOGOUT_ALERT] MESSAGE';
export const LOGOUT_ALERT__TITLE = '[LOGOUT_ALERT] TITLE';

export const TAB_ROUTE__HOME_NAME = '[TAB_ROUTE] HOME_NAME';
export const TAB_ROUTE__BOOKINGS_NAME = '[TAB_ROUTE] BOOKINGS_NAME';
export const TAB_ROUTE__COLLEGUES_NAME = '[TAB_ROUTE] COLLEGUES_NAME';
export const TAB_ROUTE__UPCOMING_NAME = '[TAB_ROUTE] UPCOMING_NAME';

export const PROFILE__SCREEN_TITLE = '[PROFILE] SCREEN_TITLE';
export const PROFILE__BUTTON_MY_BOOKINGS = '[PROFILE] BUTTON_MY_BOOKINGS';

export const GENERAL_SETTINGS__SCREEN_TITLE = '[GENERAL_SETTINGS] SCREEN_TITLE';
export const GENERAL_SETTINGS__LANGUAGE = '[GENERAL_SETTINGS] LANGUAGE';
export const GENERAL_SETTINGS__CHANGE_LANGUAGE = '[GENERAL_SETTINGS] CHANGE_LANGUAGE';

export const LANGUAGE__ENGLISH = '[LANGUAGE] ENGLISH';
export const LANGUAGE__FRENCH = '[LANGUAGE] FRENCH';

export const LOCATION__SCREEN_TITLE = '[LOCATION] SCREEN_TITLE';
