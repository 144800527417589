import { useAppSelector } from 'store';
import { authStateSelector } from 'store/selectors/auth-selectors';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';

const Router = () => {
  const authStatus = useAppSelector(authStateSelector);
  if (!authStatus) {
    return <AuthRouter />;
  }
  return <AppRouter />;
};

export default Router;
