import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import IProfile from 'types/IProfile';
import log from 'utils/log';

export const getProfileRequest = async (): Promise<IProfile> => {
  try {
    const response: AxiosResponse<IProfile> = await getApi().get('api/authentication/test');
    return response.data;
  } catch (err) {
    log(err);
  }
  return null;
};
