import { GridColDef } from '@mui/x-data-grid';
import { renderCellExpand } from 'modules/driver-files/renderCellExpand';

export const clientSendingsGridColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 120,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 480,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 180,
  },
  {
    field: 'startDate',
    headerName: 'Started (UTC)',
    minWidth: 190,
  },
  {
    field: 'completedOn',
    headerName: 'Completed (UTC)',
    minWidth: 190,
  },
  {
    field: 'sentCount',
    headerName: 'Sent',
    sortable: false,
    width: 150,
  },
  {
    field: 'errorCount',
    headerName: 'Errors',
    sortable: false,
    width: 150,
  },
  {
    field: 'totalCount',
    headerName: 'Total',
    sortable: false,
    width: 150,
  },
  {
    field: 'errorMessages',
    headerName: 'Error Messages',
    sortable: false,
    width: 500,
    renderCell: renderCellExpand,
  },
];
