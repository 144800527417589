import { createReducer } from "@reduxjs/toolkit";
import { fleetManagerEmailSendingsSetAction, fleetManagerEmailSendingsSetErrorAction, fleetManagerEmailSendingsSetLoadingAction } from "store/actions/fleet-manager-email-sendings-actions";
import IEmailSending from "types/IEmailSending";

type FleetManagerEmailSendingsState = {
    fleetManagerEmailSendings: IEmailSending[];
    loading: boolean;
    error: boolean;
};

const initialState: FleetManagerEmailSendingsState = {
    fleetManagerEmailSendings: [],
    loading: false,
    error: false,
}

const fleetManagerEmailSendingsReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(fleetManagerEmailSendingsSetAction, (state, action) => ({ ...state, fleetManagerEmailSendings: action.payload }))
        .addCase(fleetManagerEmailSendingsSetErrorAction, (state, action) => ({...state, error: action.payload}))
        .addCase(fleetManagerEmailSendingsSetLoadingAction, (state, action) => ({...state, loading: action.payload}))
);

export default fleetManagerEmailSendingsReducer;