import { getApi } from 'api';
import axios, { AxiosResponse } from 'axios';
import ILoginRequest from 'types/requests/ILoginRequest';
import ILoginResponse from 'types/responses/ILoginResponse';
import log from 'utils/log';

export const postLoginRequest = async (data: ILoginRequest): Promise<ILoginResponse> => {
  try {
    const response: AxiosResponse<ILoginResponse> = await getApi().post('api/authentication/login', data);
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};
