import { Box, Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { emailBouncesFetchAsyncAction } from 'store/actions/email-bounces-actions';
import { emailBouncesLoadingSelector, emailBouncesSelector } from 'store/selectors/email-bounces-selectors';
import { columns } from './constants';

const EmailBounces = () => {
  const dispatch = useAppDispatch();
  const rows = useAppSelector(emailBouncesSelector);
  const loading = useAppSelector(emailBouncesLoadingSelector);
  useEffect(() => {
    dispatch(emailBouncesFetchAsyncAction());
  }, []);

  const renderTable = () => {
    return (
      <Box component="div" height="670px" width="100%" mb={2}>
        <DataGrid getRowId={(r) => r.created} loading={loading} rows={rows} columns={columns} pageSize={10} disableSelectionOnClick disableColumnSelector />
      </Box>
    );
  };
  return (
    <Box component="div">
      <Box component="div" display="flex" justifyContent="space-between" mb={2}>
        <Box component="h2" mt={0}>
          Email Bounces
        </Box>

        <label htmlFor="contained-button-file">
          <Button
            size="large"
            color="primary"
            variant="contained"
            component="span"
            onClick={() => {
              dispatch(emailBouncesFetchAsyncAction());
            }}
          >
            Refresh
          </Button>
        </label>
      </Box>
      {renderTable()}
    </Box>
  );
};

export default EmailBounces;
