import Auth from 'modules/auth';
import Register from 'modules/register';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

const AuthRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/auth/login">
          <Auth />
        </Route>
        <Route exact path="/auth/register">
          <Register />
        </Route>
        <Redirect to="/auth/login" />
      </Switch>
    </BrowserRouter>
  );
};

export default AuthRouter;
