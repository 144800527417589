import { AUTH__TOKEN_KEY } from 'constants/storage';
import store from 'store';
import { setAuthTokenAction } from 'store/actions/auth-actions';

const loadAuth = async (): Promise<void> => {
  const token = localStorage.getItem(AUTH__TOKEN_KEY);
  if (token) {
    store.dispatch(setAuthTokenAction(token));
  }
};

export default loadAuth;
