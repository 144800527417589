import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getDriverFilesRequest, postCsvUploadRequest } from 'api/requests/driver-files-requests';
import { DRIVER_FILES__SET, DRIVER_FILES__SET_LOADING, DRIVER_FILES__SET_ERROR, DRIVER_FILES__FETCH, DRIVER_FILES__CREATE, DRIVER_FILES__SET_UPLOAD_LOADING } from 'store/constants';
import IDriverFile from 'types/IDriverFile';

export const driverFilesSetAction = createAction<IDriverFile[]>(DRIVER_FILES__SET);
export const driverFilesSetLoadingAction = createAction<boolean>(DRIVER_FILES__SET_LOADING);
export const driverFilesSetErrorAction = createAction<boolean>(DRIVER_FILES__SET_ERROR);
export const driverFilesSetUploadLoadingAction = createAction<boolean>(DRIVER_FILES__SET_UPLOAD_LOADING);

export const driverFilesFetchAsyncAction = createAsyncThunk(DRIVER_FILES__FETCH, async (_: never, thunkApi) => {
  // Set the loading state to true - we can use this state to show a spinner or disable the button
  thunkApi.dispatch(driverFilesSetLoadingAction(true));
  // Fire the request and wait for the response
  const response = await getDriverFilesRequest();

  // Soft validation on the response, so we don't populate the store with invalid data
  if (!response) {
    thunkApi.dispatch(driverFilesSetErrorAction(true));
  } else {
    thunkApi.dispatch(driverFilesSetAction(response));
  }

  // Set the loading state to false
  thunkApi.dispatch(driverFilesSetLoadingAction(false));
});

export const driverFilesCreateAsyncAction = createAsyncThunk(DRIVER_FILES__CREATE, async (data: File, thunkApi) => {
  // Set the loading state to true - we can use this state to show a spinner or disable the button
  thunkApi.dispatch(driverFilesSetUploadLoadingAction(true));

  // Fire the request and wait for the response
  const response = await postCsvUploadRequest(data);

  // Soft validation on the response, so we don't populate the store with invalid data
  if (response) {
    thunkApi.dispatch(driverFilesFetchAsyncAction());
  }

  // Set the loading state to false
  thunkApi.dispatch(driverFilesSetUploadLoadingAction(false));
});
