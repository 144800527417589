/* eslint-disable react/jsx-props-no-spreading */
import { Box, Card, CardHeader, CardContent, Button, TextField, CircularProgress } from '@material-ui/core';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchAuthLoginAsyncAction, setAuthErrorAction } from 'store/actions/auth-actions';
import { authErrorSelector, authLoadingSelector } from 'store/selectors/auth-selectors';
import ILoginFormInputs from 'types/forms/ILoginFormInputs';
import useStyles from './styles';

const Auth = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormInputs>();
  const onLogin: SubmitHandler<ILoginFormInputs> = (data) => dispatch(fetchAuthLoginAsyncAction({ email: data.Email, password: data.Password }));

  const authLoading = useAppSelector(authLoadingSelector);
  const authError = useAppSelector(authErrorSelector);

  const createOnChange = (onChange: (...event: any[]) => void) => {
    return (ev: any) => {
      if (authError) {
        dispatch(setAuthErrorAction(false));
      }
      onChange(ev);
    };
  };

  return (
    <div className={classes.mainWrapper}>
      <Card className={classes.mainContainer}>
        <CardHeader title="Authenticate" />
        <CardContent>
          <Controller
            name="Email"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => <TextField {...field} label={field.name} type="email" variant="outlined" fullWidth error={!!errors.Email || authError} onChange={createOnChange(field.onChange)} />}
          />
          <Box component="div" mt={2}>
            <Controller
              name="Password"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField {...field} label={field.name} type="password" variant="outlined" fullWidth error={!!errors.Password || authError} onChange={createOnChange(field.onChange)} />
              )}
            />
          </Box>
          {authError && (
            <Box component="div" color="red">
              Invalid credentials
            </Box>
          )}
          <Box component="div" mt={4} display="flex">
            <Box component="div" maxWidth="120px" width="100%" position="relative">
              <Button disabled={authLoading} onClick={handleSubmit(onLogin)} size="large" color="primary" variant="contained" fullWidth>
                Login
              </Button>
              <Box component="div" position="absolute" top="50%" left="50%" mt="-12px" ml="-12px">
                {authLoading && <CircularProgress size={24} />}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default Auth;
