import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import log from 'utils/log';

export const getCsvWithInsuranceCertificatesDataRequest = async (): Promise<boolean> => {
  try {
    const response: AxiosResponse<Blob> = await getApi().get(`api/insurancecertificatedata/get-insurance-certificates-file`);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'InsuranceCertificates.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return true;
  } catch (err) {
    log(err);
  }
  return false;
};
