import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import log from 'utils/log';
import en from './lang/en';
import fr from './lang/fr';

const options = {
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
};

export const initTranslations = async (): Promise<void> => {
  try {
    i18next.use(initReactI18next);
    await i18next.init(options);
  } catch (err) {
    log(err);
  }
};
