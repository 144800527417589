import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllRendersDisplay } from 'api/requests/renders-request';
import { FIRST_RENDERS__FETCH, FIRST_RENDERS__SET, FIRST_RENDERS__SET_ERROR, FIRST_RENDERS__SET_LOADING } from 'store/constants';
import IRenders from 'types/IRenders';

export const firstRendersSetAction = createAction<IRenders[]>(FIRST_RENDERS__SET);
export const firstRendersSetLoadingAction = createAction<boolean>(FIRST_RENDERS__SET_LOADING);
export const firstRendersSetErrorAction = createAction<boolean>(FIRST_RENDERS__SET_ERROR);

export const firstRendersFetchAsyncAction = createAsyncThunk(FIRST_RENDERS__FETCH, async (_: never, thunkApi) => {
  // Set the loading state to true - we can use this state to show a spinner or disable the button
  thunkApi.dispatch(firstRendersSetLoadingAction(true));
  // Fire the request and wait for the response
  const response = await getAllRendersDisplay('First');

  // Soft validation on the response, so we don't populate the store with invalid data
  if (!response) {
    thunkApi.dispatch(firstRendersSetErrorAction(true));
  } else {
    thunkApi.dispatch(firstRendersSetAction(response));
  }

  // Set the loading state to false
  thunkApi.dispatch(firstRendersSetLoadingAction(false));
});
