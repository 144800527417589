import { createReducer } from '@reduxjs/toolkit';
import { emailBouncesSetAction, emailBouncesSetErrorAction, emailBouncesSetLoadingAction } from 'store/actions/email-bounces-actions';
import IEmailBounces from 'types/IEmailBounces';

type EmailBouncesState = {
  contracts: IEmailBounces[];
  loading: boolean;
  error: boolean;
};

const initialState: EmailBouncesState = {
  contracts: [],
  loading: false,
  error: false,
};

const EmailBouncesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(emailBouncesSetAction, (state, action) => ({ ...state, contracts: action.payload }))
    .addCase(emailBouncesSetErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(emailBouncesSetLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    })),
);

export default EmailBouncesReducer;
