import { GridColDef } from '@mui/x-data-grid';
import { renderCellExpand } from 'modules/driver-files/renderCellExpand';

export const columns: GridColDef[] = [
  {
    field: 'createdDate',
    headerName: 'Created Date',
    type: 'date',
    width: 170,
  },
  {
    field: 'email',
    headerName: 'Email address',
    width: 300,
  },
  {
    field: 'reason',
    headerName: 'Reason',
    sortable: false,
    minWidth: 1200,
    renderCell: renderCellExpand,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    sortable: false,
  },
];
