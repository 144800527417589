import { createReducer } from '@reduxjs/toolkit';
import { leasingContractsSetAction, leasingContractsSetErrorAction, leasingContractsSetLoadingAction } from 'store/actions/leasing-contracts-actions';
import ILeasingContract from '../../types/ILeasingContract';

type LeasingContractsState = {
  contracts: ILeasingContract[];
  loading: boolean;
  error: boolean;
};

const initialState: LeasingContractsState = {
  contracts: [],
  loading: false,
  error: false,
};

const leasingContractsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(leasingContractsSetAction, (state, action) => ({ ...state, contracts: action.payload }))
    .addCase(leasingContractsSetErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(leasingContractsSetLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    })),
);

export default leasingContractsReducer;
