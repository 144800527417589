import { createReducer } from '@reduxjs/toolkit';
import { setAuthErrorAction, setAuthLoadingAction, setAuthTokenAction } from 'store/actions/auth-actions';

type AuthState = {
  token: string;
  loading: boolean;
  error: boolean;
};

const initialState: AuthState = {
  token: null,
  loading: false,
  error: false,
};

const authReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setAuthTokenAction, (state, action) => ({ ...state, token: action.payload }))
    .addCase(setAuthErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(setAuthLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    })),
);

export default authReducer;
