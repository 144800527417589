import { Box, Button, CircularProgress } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store';
import { driverFilesCreateAsyncAction, driverFilesFetchAsyncAction } from 'store/actions/driver-files-actions';
import { driverFilesLoadingSelector, driverFilesSelector, driverFilesUploadLoadingSelector } from 'store/selectors/driver-files-selectors';
import { columns } from './constants';
import { Input } from './styles';

const DriverFiles = () => {
  const dispatch = useAppDispatch();
  const uploadLoading = useAppSelector(driverFilesUploadLoadingSelector);
  const loading = useAppSelector(driverFilesLoadingSelector);
  const rows = useAppSelector(driverFilesSelector);
  useEffect(() => {
    dispatch(driverFilesFetchAsyncAction());
  }, []);
  const onUpload: SubmitHandler<File> = (data) => dispatch(driverFilesCreateAsyncAction(data));

  const renderTable = () => {
    return (
      <Box component="div" height="670px" width="100%" mb={2}>
        <DataGrid loading={loading} autoHeight rows={rows} columns={columns} pageSize={10} disableSelectionOnClick disableColumnSelector />
      </Box>
    );
  };
  return (
    <Box component="div">
      <Box component="div" display="flex" justifyContent="space-between" mb={2}>
        <Box component="h2" mt={0}>
          Drivers Csv Files
        </Box>
        <label htmlFor="contained-button-file">
          <Input
            onChange={(ev) => {
              onUpload(ev.target.files[0]);
            }}
            accept=".csv"
            id="contained-button-file"
            type="file"
          />
          <Button disabled={uploadLoading} size="large" color="primary" variant="contained" component="span">
            Upload Csv {uploadLoading && <CircularProgress size={24} />}
          </Button>
        </label>
      </Box>
      {renderTable()}
    </Box>
  );
};

export default DriverFiles;
