import { Box, Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { getCsvWithRendersRequest } from 'api/requests/renders-request';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { firstRendersFetchAsyncAction } from 'store/actions/first-renders-actions';
import { firstRendersLoadingSelector, firstRendersSelector } from 'store/selectors/first-renders-selectors';
import { columns } from './constants';

const FirstRenders = () => {
  const dispatch = useAppDispatch();
  const rows = useAppSelector(firstRendersSelector);
  const loading = useAppSelector(firstRendersLoadingSelector);
  useEffect(() => {
    dispatch(firstRendersFetchAsyncAction());
  }, []);

  const renderTable = () => {
    return (
      <Box component="div" height="670px" width="100%" mb={2}>
        <DataGrid loading={loading} rows={rows} columns={columns} pageSize={10} disableSelectionOnClick disableColumnSelector />
      </Box>
    );
  };
  return (
    <Box component="div">
      <Box component="div" display="flex" justifyContent="space-between" mb={2}>
        <Box component="h2" mt={0}>
          First Renders
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          component="span"
          onClick={() => {
            getCsvWithRendersRequest('First');
          }}
        >
          Download Csv
        </Button>
      </Box>
      <Box component="div" height="670px" width="100%" mb={2}>
        {renderTable()}
      </Box>
    </Box>
  );
};

export default FirstRenders;
