import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch } from 'store';
import { doAuthLogoutAsyncAction } from 'store/actions/auth-actions';

const Logout = () => {
  const dispatch = useAppDispatch();
  const [, , removeCookie] = useCookies(['HangFireCookie']);
  removeCookie('HangFireCookie');
  useEffect(() => {
    dispatch(doAuthLogoutAsyncAction());
  }, []);

  return <div />;
};

export default Logout;
