import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllBouncesRequest } from 'api/requests/emails-requests';
import { EMAIL_BOUNCES__FETCH, EMAIL_BOUNCES__SET, EMAIL_BOUNCES__SET_ERROR, EMAIL_BOUNCES__SET_LOADING } from 'store/constants';
import IEmailBounces from 'types/IEmailBounces';

export const emailBouncesSetAction = createAction<IEmailBounces[]>(EMAIL_BOUNCES__SET);
export const emailBouncesSetLoadingAction = createAction<boolean>(EMAIL_BOUNCES__SET_LOADING);
export const emailBouncesSetErrorAction = createAction<boolean>(EMAIL_BOUNCES__SET_ERROR);

export const emailBouncesFetchAsyncAction = createAsyncThunk(EMAIL_BOUNCES__FETCH, async (_: never, thunkApi) => {
  // Set the loading state to true - we can use this state to show a spinner or disable the button
  thunkApi.dispatch(emailBouncesSetLoadingAction(true));
  // Fire the request and wait for the response
  const response = await getAllBouncesRequest();

  // Soft validation on the response, so we don't populate the store with invalid data
  if (!response) {
    thunkApi.dispatch(emailBouncesSetErrorAction(true));
  } else {
    thunkApi.dispatch(emailBouncesSetAction(response));
  }

  // Set the loading state to false
  thunkApi.dispatch(emailBouncesSetLoadingAction(false));
});
