import { Box, Button, CircularProgress } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store';
import { leasingCompaniesCreateAsyncAction, leasingCompaniesFetchAsyncAction } from 'store/actions/leasing-companies-actions';
import { leasingCompaniesLoadingSelector, leasingCompaniesSelector, leasingCompaniesUploadLoadingSelector, leasingCompaniesUploadResponseSelector } from 'store/selectors/leasing-companies-selectors';
import { columns } from './constants';
import { Input, useStyles } from './styles';

const LeasingCompanies = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const uploadLoading = useAppSelector(leasingCompaniesUploadLoadingSelector);
  const loading = useAppSelector(leasingCompaniesLoadingSelector);
  const rows = useAppSelector(leasingCompaniesSelector);
  const uploadResponse = useAppSelector(leasingCompaniesUploadResponseSelector);
  useEffect(() => {
    dispatch(leasingCompaniesFetchAsyncAction());
  }, []);
  const onUpload: SubmitHandler<File> = (data) => dispatch(leasingCompaniesCreateAsyncAction(data));

  const renderTable = () => {
    return (
      <Box component="div" height="670px" width="100%" mb={2}>
        <DataGrid loading={loading} autoHeight rows={rows} columns={columns} pageSize={10} disableSelectionOnClick disableColumnSelector />
        <p className={classes.errorMessageRed}> {uploadResponse}</p>
      </Box>
    );
  };
  return (
    <Box component="div">
      <Box component="div" display="flex" justifyContent="space-between" mb={2}>
        <Box component="h2" mt={0}>
          Leasing Companies
        </Box>
        <label htmlFor="contained-button-file">
          <Input
            onChange={(ev) => {
              onUpload(ev.target.files[0]);
            }}
            accept=".csv"
            id="contained-button-file"
            type="file"
          />
          <Button disabled={uploadLoading} size="large" color="primary" variant="contained" component="span">
            Upload Csv {uploadLoading && <CircularProgress size={24} />}
          </Button>
        </label>
      </Box>
      {renderTable()}
    </Box>
  );
};

export default LeasingCompanies;
