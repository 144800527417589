import BusinessIcon from '@material-ui/icons/Business';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import FirstRenderIcon from '@material-ui/icons/Done';
import RepeatRenderIcon from '@material-ui/icons/DoneAll';
import Dashboard from '@material-ui/icons/Dashboard';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import SendIcon from '@material-ui/icons/Send';

export const drawerWidth = 240;

export const menuItems = [
  {
    name: 'Dashboard',
    icon: () => <Dashboard />,
    route: '/dashboard',
  },
  {
    name: 'Csv Upload',
    icon: () => <FileCopyOutlinedIcon />,
    route: '/driver-files',
  },
  {
    name: 'Leasing Contracts',
    icon: () => <DirectionsCarOutlinedIcon />,
    route: '/leasing-contracts',
  },
  {
    name: 'Leasing Companies',
    icon: () => <BusinessIcon />,
    route: '/leasing-companies',
  },
  {
    name: 'First Renders',
    icon: () => <FirstRenderIcon />,
    route: '/first-renders',
  },
  {
    name: 'Repeat Renders',
    icon: () => <RepeatRenderIcon />,
    route: '/repeat-renders',
  },
  {
    name: 'Email Bounces',
    icon: () => <MailOutlineIcon />,
    route: '/email-bounces',
  },
  {
    name: 'Client Emailing',
    icon: () => <SendIcon color="secondary" />,
    route: '/client-email-sending'
  },
  {
    name: 'Fleet Mngr Emailing',
    icon: () => <SendIcon color="primary" />,
    route: '/fleet-manager-email-sending'
  },
];

export const menuItemsSecondary = [
  {
    name: 'Logout',
    icon: () => <ExitToAppIcon />,
    route: '/logout',
  },
];
