import { getRendersRequest, getCsvWithIndividualRendersRequest } from 'api/requests/renders-request';
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import FirstRenderIcon from '@material-ui/icons/Done';
import RepeatRenderIcon from '@material-ui/icons/DoneAll';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getNumberOfBouncesRequest } from 'api/requests/emails-requests';
import { getCsvWithInsuranceCertificatesDataRequest } from 'api/requests/insurance-certificate-data-requests';
import useStyles from './styles';

const Dashboard = () => {
  const history = useHistory();
  const classes = useStyles();
  const [firstRenders, setFirstRenders] = useState(null);
  const [repeatRenders, setRepeatRenders] = useState(null);
  const [emailBounces, setEmailBounces] = useState(null);

  const requestCardsData = async () => {
    const nrFirstRenders = await getRendersRequest('First');
    const nrRepeatRenders = await getRendersRequest('Repeat');
    const nrBounces = await getNumberOfBouncesRequest();
    setFirstRenders(nrFirstRenders);
    setRepeatRenders(nrRepeatRenders);
    setEmailBounces(nrBounces);
  };

  useEffect(() => {
    requestCardsData();
  }, []);

  return (
    <div>
      <div className={classes.rendersContainerFlex}>
        <Grid container direction="column">
          <Grid container direction="row" justifyContent="space-evenly">
            <div className={classes.rendersContainerCard}>
              <Grid container direction="row">
                <Card
                  className={classes.rendersContainerCardIcon}
                  onClick={() => {
                    history.push('/first-renders');
                  }}
                >
                  <FirstRenderIcon className={classes.iconLargeWhite} />
                </Card>
                <Card
                  className={classes.rendersContainerCardValue}
                  onClick={() => {
                    history.push('/first-renders');
                  }}
                >
                  <CardHeader titleTypographyProps={{ variant: 'h3' }} title={firstRenders} />
                  <CardContent>
                    <div color="white">First renders</div>
                  </CardContent>
                </Card>
              </Grid>
            </div>
            <div className={classes.rendersContainerCard}>
              <Grid container direction="row">
                <Card
                  className={classes.rendersContainerCardIcon}
                  onClick={() => {
                    history.push('/repeat-renders');
                  }}
                >
                  <RepeatRenderIcon className={classes.iconLargeWhite} />
                </Card>
                <Card
                  className={classes.rendersContainerCardValue}
                  onClick={() => {
                    history.push('/repeat-renders');
                  }}
                >
                  <CardHeader titleTypographyProps={{ variant: 'h3' }} title={repeatRenders} />
                  <CardContent>
                    <div color="white">Repeat renders</div>
                  </CardContent>
                </Card>
              </Grid>
            </div>
            <div className={classes.rendersContainerCard}>
              <Grid container direction="row">
                <Card
                  className={classes.rendersContainerCardIcon}
                  onClick={() => {
                    history.push('/email-bounces');
                  }}
                >
                  <MailOutlineIcon className={classes.iconLargeWhite} />
                </Card>
                <Card
                  className={classes.rendersContainerCardValue}
                  onClick={() => {
                    history.push('/email-bounces');
                  }}
                >
                  <CardHeader titleTypographyProps={{ variant: 'h3' }} title={emailBounces} />
                  <CardContent>
                    <div color="white">Email bounces</div>
                  </CardContent>
                </Card>
              </Grid>
            </div>
          </Grid>
          <Grid container direction="row" justifyContent="space-evenly">
          <div className={classes.rendersContainerCard}>
              <Grid container direction="row">
                <Card
                  className={classes.rendersContainerCardIcon}
                  onClick={() => {
                    getCsvWithIndividualRendersRequest();
                  }}
                >
                  <ArrowDownwardOutlined className={classes.iconLargeWhite} />
                </Card>
                <Card
                  className={classes.rendersContainerCardValue}
                  onClick={() => {
                    getCsvWithIndividualRendersRequest();
                  }}
                >
                  <CardHeader titleTypographyProps={{ variant: 'h3' }} />
                  <CardContent>
                    <div color="white">Download CSV file with render information</div>
                  </CardContent>
                </Card>
              </Grid>
            </div>
            <div className={classes.rendersContainerCard}>
              <Grid container direction="row">
                <Card
                  className={classes.rendersContainerCardIcon}
                  onClick={() => {
                    getCsvWithInsuranceCertificatesDataRequest();
                  }}
                >
                  <ArrowDownwardOutlined className={classes.iconLargeWhite} />
                </Card>
                <Card
                  className={classes.rendersContainerCardValue}
                  onClick={() => {
                    getCsvWithInsuranceCertificatesDataRequest();
                  }}
                >
                  <CardHeader titleTypographyProps={{ variant: 'h3' }} />
                  <CardContent>
                    <div color="white">Download CSV file with driver information</div>
                  </CardContent>
                </Card>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
