import { getApi } from 'api';
import axios, { AxiosResponse } from 'axios';
import log from 'utils/log';
import IGetDriversFilesResponse from 'types/responses/IGetDriversFilesResponse';
import IDriverFile from 'types/IDriverFile';

export const postCsvUploadRequest = async (data: File): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append('file', data);
    const response: AxiosResponse<boolean> = await getApi().post('api/csvupload/upload', formData);
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};

export const getDriverFilesRequest = async (): Promise<IDriverFile[]> => {
  try {
    const response: AxiosResponse<IGetDriversFilesResponse> = await getApi().get('api/csvupload/display');
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};
