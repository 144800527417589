import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getLeasingCompaniesRequest, postLeasingCompaniesRequest } from 'api/requests/leasing-companies-requests';
import {
  LEASING_COMPANIES__SET,
  LEASING_COMPANIES__SET_LOADING,
  LEASING_COMPANIES__SET_ERROR,
  LEASING_COMPANIES__FETCH,
  LEASING_COMPANIES__CREATE,
  LEASING_COMPANIES__SET_UPLOAD_LOADING,
  LEASING_COMPANIES__SET_UPLOAD_RESPONSE,
} from 'store/constants';
import ILeasingCompanies from 'types/ILeasingCompanies';

export const leasingCompaniesSetAction = createAction<ILeasingCompanies[]>(LEASING_COMPANIES__SET);
export const leasingCompaniesSetLoadingAction = createAction<boolean>(LEASING_COMPANIES__SET_LOADING);
export const leasingCompaniesSetErrorAction = createAction<boolean>(LEASING_COMPANIES__SET_ERROR);
export const leasingCompaniesSetUploadLoadingAction = createAction<boolean>(LEASING_COMPANIES__SET_UPLOAD_LOADING);
export const leasingCompaniesSetUploadResponseAction = createAction<string>(LEASING_COMPANIES__SET_UPLOAD_RESPONSE);

export const leasingCompaniesFetchAsyncAction = createAsyncThunk(LEASING_COMPANIES__FETCH, async (_: never, thunkApi) => {
  // Set the loading state to true - we can use this state to show a spinner or disable the button
  thunkApi.dispatch(leasingCompaniesSetLoadingAction(true));
  // Fire the request and wait for the response
  const response = await getLeasingCompaniesRequest();

  // Soft validation on the response, so we don't populate the store with invalid data
  if (!response) {
    thunkApi.dispatch(leasingCompaniesSetErrorAction(true));
  } else {
    thunkApi.dispatch(leasingCompaniesSetAction(response));
  }

  // Set the loading state to false
  thunkApi.dispatch(leasingCompaniesSetLoadingAction(false));
});

export const leasingCompaniesCreateAsyncAction = createAsyncThunk(LEASING_COMPANIES__CREATE, async (data: File, thunkApi) => {
  // Set the loading state to true - we can use this state to show a spinner or disable the button
  thunkApi.dispatch(leasingCompaniesSetUploadLoadingAction(true));

  // Fire the request and wait for the response
  const response = await postLeasingCompaniesRequest(data);
  // Soft validation on the response, so we don't populate the store with invalid data
  if (response === 'Upload completed!') {
    thunkApi.dispatch(leasingCompaniesFetchAsyncAction());
    thunkApi.dispatch(leasingCompaniesSetUploadResponseAction(''));
  } else {
    thunkApi.dispatch(leasingCompaniesSetUploadResponseAction(response));
  }

  // Set the loading state to false
  thunkApi.dispatch(leasingCompaniesSetUploadLoadingAction(false));
});
