import { createReducer } from '@reduxjs/toolkit';
import { repeatRendersSetAction, repeatRendersSetErrorAction, repeatRendersSetLoadingAction } from 'store/actions/repeat-renders-actions';
import IRenders from 'types/IRenders';

type repeatRendersState = {
  contracts: IRenders[];
  loading: boolean;
  error: boolean;
};

const initialState: repeatRendersState = {
  contracts: [],
  loading: false,
  error: false,
};

const repeatRendersReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(repeatRendersSetAction, (state, action) => ({ ...state, contracts: action.payload }))
    .addCase(repeatRendersSetErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(repeatRendersSetLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    })),
);

export default repeatRendersReducer;
