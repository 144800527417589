import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import IRenders from 'types/IRenders';
import IRendersResponse from 'types/responses/IRendersResponse';
import log from 'utils/log';

export const getRendersRequest = async (data: string): Promise<number> => {
  try {
    const response: AxiosResponse<number> = await getApi().post(`api/renders/get-renders/${data}`);
    return response.data;
  } catch (err) {
    log(err);
  }
  return null;
};

export const getCsvWithIndividualRendersRequest = async (): Promise<boolean> => {
  try {
    const response: AxiosResponse<Blob> = await getApi().get(`api/renders/get-renders-file`);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'IndividualRenders.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return true;
  } catch (err) {
    log(err);
  }
  return false;
};

export const getAllRendersDisplay = async (data: string): Promise<IRenders[]> => {
  try {
    const response: AxiosResponse<IRendersResponse> = await getApi().get(`api/renders/get-all/${data}`);
    return response.data;
  } catch (err) {
    log(err);
  }
  return null;
};

export const getCsvWithRendersRequest = async (data: string): Promise<boolean> => {
  try {
    const response: AxiosResponse<Blob> = await getApi().get(`api/renders/get-renders-file/${data}`);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${data}Renders.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return true;
  } catch (err) {
    log(err);
  }
  return false;
};
