import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getProfileRequest } from 'api/requests/profile-requests';
import { PROFILE__FETCH, PROFILE__SET } from 'store/constants';
import IProfile from 'types/IProfile';

export const setProfileAction = createAction<IProfile>(PROFILE__SET);

export const fetchProfileAsync = createAsyncThunk(PROFILE__FETCH, async (_: never, thunkApi) => {
  const profile = await getProfileRequest();
  if (profile) {
    thunkApi.dispatch(setProfileAction(profile));
  }
});
