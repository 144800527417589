import { createTheme } from '@material-ui/core/styles';
import { deepPurple, amber } from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: deepPurple[400],
    },
    secondary: {
      main: amber.A400,
    },
  },
});

export default theme;
