import * as translations from '../constants';

export default {
  [translations.LOGIN__EMAIL_LABEL]: 'Email',
  [translations.LOGIN__PASSWORD_LABEL]: 'Password',
  [translations.LOGIN__CTA]: 'Login and Continue',
  [translations.LOGIN__ERROR]: 'These credentials are not valid',

  [translations.DRAWER__PROFILE]: 'Your Profile',
  [translations.DRAWER__GENERAL_SETTINGS]: 'General Settings',
  [translations.DRAWER__LOGOUT]: 'Logout',

  [translations.LOGOUT_ALERT__TITLE]: 'Log out',
  [translations.LOGOUT_ALERT__MESSAGE]: 'Are you sure you want to log out?',
  [translations.LOGOUT_ALERT__CANCEL_BUTTON]: 'Cancel',
  [translations.LOGOUT_ALERT__CONFIRM_BUTTON]: 'Log out',

  [translations.TAB_ROUTE__HOME_NAME]: 'Home',
  [translations.TAB_ROUTE__BOOKINGS_NAME]: 'Bookings',
  [translations.TAB_ROUTE__UPCOMING_NAME]: 'Upcoming',
  [translations.TAB_ROUTE__COLLEGUES_NAME]: 'Collegues',

  [translations.PROFILE__SCREEN_TITLE]: 'Profile',
  [translations.PROFILE__BUTTON_MY_BOOKINGS]: 'Check My Bookings',

  [translations.GENERAL_SETTINGS__SCREEN_TITLE]: 'General Settings',
  [translations.GENERAL_SETTINGS__LANGUAGE]: 'Language',
  [translations.GENERAL_SETTINGS__CHANGE_LANGUAGE]: 'Change Language',

  [translations.LANGUAGE__ENGLISH]: 'English',
  [translations.LANGUAGE__FRENCH]: 'French',

  [translations.LOCATION__SCREEN_TITLE]: 'Location',
};
