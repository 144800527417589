import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllClientEmailSendings } from 'api/requests/email-sendings-requests';
import { CLIENT_EMAIL_SENDINGS__FETCH, CLIENT_EMAIL_SENDINGS__SET, CLIENT_EMAIL_SENDINGS__SET_LOADING, CLIENT_EMAIL_SENDINGS__SET_ERROR } from 'store/constants';
import IEmailSending from 'types/IEmailSending';

export const clientEmailSendingsSetAction = createAction<IEmailSending[]>(CLIENT_EMAIL_SENDINGS__SET);
export const clientEmailSendingsSetLoadingAction = createAction<boolean>(CLIENT_EMAIL_SENDINGS__SET_LOADING);
export const clientEmailSendingsSetErrorAction = createAction<boolean>(CLIENT_EMAIL_SENDINGS__SET_ERROR);

export const clientEmailSendingsFetchAsyncAction = createAsyncThunk(CLIENT_EMAIL_SENDINGS__FETCH, async (_: never, thunkApi) => {
    thunkApi.dispatch(clientEmailSendingsSetLoadingAction(true));

    const response = await getAllClientEmailSendings();

    if(!response) {
        thunkApi.dispatch(clientEmailSendingsSetErrorAction(true));
    } else {
        thunkApi.dispatch(clientEmailSendingsSetAction(response));
    }

    thunkApi.dispatch(clientEmailSendingsSetLoadingAction(false));
});