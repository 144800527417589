import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllRendersDisplay } from 'api/requests/renders-request';
import { REPEAT_RENDERS__FETCH, REPEAT_RENDERS__SET, REPEAT_RENDERS__SET_ERROR, REPEAT_RENDERS__SET_LOADING } from 'store/constants';
import IRenders from 'types/IRenders';

export const repeatRendersSetAction = createAction<IRenders[]>(REPEAT_RENDERS__SET);
export const repeatRendersSetLoadingAction = createAction<boolean>(REPEAT_RENDERS__SET_LOADING);
export const repeatRendersSetErrorAction = createAction<boolean>(REPEAT_RENDERS__SET_ERROR);

export const repeatRendersFetchAsyncAction = createAsyncThunk(REPEAT_RENDERS__FETCH, async (_: never, thunkApi) => {
  // Set the loading state to true - we can use this state to show a spinner or disable the button
  thunkApi.dispatch(repeatRendersSetLoadingAction(true));
  // Fire the request and wait for the response
  const response = await getAllRendersDisplay('Repeat');

  // Soft validation on the response, so we don't populate the store with invalid data
  if (!response) {
    thunkApi.dispatch(repeatRendersSetErrorAction(true));
  } else {
    thunkApi.dispatch(repeatRendersSetAction(response));
  }

  // Set the loading state to false
  thunkApi.dispatch(repeatRendersSetLoadingAction(false));
});
