import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90, hide: true },
  {
    field: 'll',
    headerName: 'LL',
    sortable: false,
    width: 100,
  },
  {
    field: 'name',
    headerName: 'NaamLL',
    type: 'date',
    width: 250,
  },
  {
    field: 'address',
    headerName: 'AdresLL',
    width: 200,
    sortable: false,
  },
  {
    field: 'houseNumber',
    headerName: 'HuisnrLL',
    width: 150,
    sortable: false,
  },
  {
    field: 'postalNumber',
    headerName: 'PostnrLL',
    width: 150,
    sortable: false,
  },
  {
    field: 'township',
    headerName: 'GemeenteLL',
    width: 200,
    sortable: false,
  },
  {
    field: 'addendum',
    headerName: 'BijvoegselLL',
    width: 200,
    sortable: false,
  },
  {
    field: 'country',
    headerName: 'LandLL',
    width: 200,
    sortable: false,
  },
  {
    field: 'assistance1',
    headerName: 'BijstandLL(1)',
    width: 200,
    sortable: false,
  },
  {
    field: 'assistance2',
    headerName: 'BijstandLL(2)',
    width: 200,
    sortable: false,
  },
];
