import { setConfig } from 'config';
import IConfig from 'types/IConfig';
import ConfigNotFoundException from 'exceptions/ConfigNotFoundException';

const getConfig = async (): Promise<IConfig> => {
  let jsonConfig: IConfig = null;

  try {
    const response = await fetch('/config.json');
    jsonConfig = await response.json();
  } catch (err) {
    throw new ConfigNotFoundException('Please provide a valid config file');
  }

  return jsonConfig;
};

const loadConfig = async (): Promise<void> => {
  const configJson = await getConfig();
  setConfig(configJson);
};

export default loadConfig;
