import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllFleetManagerEmailSendings } from "api/requests/email-sendings-requests";
import { FLEET_MANAGER_EMAIL_SENDINGS__FETCH, FLEET_MANAGER_EMAIL_SENDINGS__SET, FLEET_MANAGER_EMAIL_SENDINGS__SET_ERROR, FLEET_MANAGER_EMAIL_SENDINGS__SET_LOADING } from "store/constants";
import IEmailSending from "types/IEmailSending";

export const fleetManagerEmailSendingsSetAction = createAction<IEmailSending[]>(FLEET_MANAGER_EMAIL_SENDINGS__SET);
export const fleetManagerEmailSendingsSetLoadingAction = createAction<boolean>(FLEET_MANAGER_EMAIL_SENDINGS__SET_LOADING);
export const fleetManagerEmailSendingsSetErrorAction = createAction<boolean>(FLEET_MANAGER_EMAIL_SENDINGS__SET_ERROR);

export const fleetManagerEmailSendingsFetchAsyncAction = createAsyncThunk(FLEET_MANAGER_EMAIL_SENDINGS__FETCH, async (_: never, thunkApi) => {
    thunkApi.dispatch(fleetManagerEmailSendingsSetLoadingAction(true));

    const response = await getAllFleetManagerEmailSendings();

    if(!response) {
        thunkApi.dispatch(fleetManagerEmailSendingsSetErrorAction(true));
    } else {
        thunkApi.dispatch(fleetManagerEmailSendingsSetAction(response));
    }

    thunkApi.dispatch(fleetManagerEmailSendingsSetLoadingAction(false));
});