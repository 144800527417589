import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { postLoginRequest } from 'api/requests/auth-requests';
import { AUTH__TOKEN_KEY } from 'constants/storage';
import { AUTH__SET_TOKEN, AUTH__SET_LOADING, AUTH__FETCH_LOGIN, AUTH__FETCH_LOGOUT, AUTH__SET_ERROR } from 'store/constants';
import ILoginRequest from 'types/requests/ILoginRequest';

export const setAuthTokenAction = createAction<string>(AUTH__SET_TOKEN);
export const setAuthLoadingAction = createAction<boolean>(AUTH__SET_LOADING);
export const setAuthErrorAction = createAction<boolean>(AUTH__SET_ERROR);

export const fetchAuthLoginAsyncAction = createAsyncThunk(AUTH__FETCH_LOGIN, async (data: ILoginRequest, thunkApi) => {
  // Set the loading state to true - we can use this state to show a spinner or disable the button
  thunkApi.dispatch(setAuthLoadingAction(true));

  // Fire the request and wait for the response
  const response = await postLoginRequest(data);

  // Soft validation on the response, so we don't populate the store with invalid data
  if (response?.token) {
    // We set the token in the store (for easy access)
    thunkApi.dispatch(setAuthTokenAction(response.token));

    // We set the token in local storage for semi-permanent storage (so we can restore it from local storage and skip the login request)
    localStorage.setItem(AUTH__TOKEN_KEY, response.token);
  } else {
    thunkApi.dispatch(setAuthErrorAction(true));
  }

  // Set the loading state to false
  thunkApi.dispatch(setAuthLoadingAction(false));
});

export const doAuthLogoutAsyncAction = createAsyncThunk(AUTH__FETCH_LOGOUT, async (_: never, thunkApi) => {
  thunkApi.dispatch(setAuthTokenAction(null));
  localStorage.removeItem(AUTH__TOKEN_KEY);
});
