import { makeStyles, styled } from '@material-ui/core';

export const Input = styled('input')({
  display: 'none',
});

export const useStyles = makeStyles({
  errorMessageRed: {
    color: 'red',
  },
});
