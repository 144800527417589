import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getLeasingContractsRequest } from 'api/requests/leasing-contracts-requests';
import { LEASING_CONTRACTS__FETCH, LEASING_CONTRACTS__SET, LEASING_CONTRACTS__SET_ERROR, LEASING_CONTRACTS__SET_LOADING } from 'store/constants';
import ILeasingContract from '../../types/ILeasingContract';

export const leasingContractsSetAction = createAction<ILeasingContract[]>(LEASING_CONTRACTS__SET);
export const leasingContractsSetLoadingAction = createAction<boolean>(LEASING_CONTRACTS__SET_LOADING);
export const leasingContractsSetErrorAction = createAction<boolean>(LEASING_CONTRACTS__SET_ERROR);

export const leasingContractsFetchAsyncAction = createAsyncThunk(LEASING_CONTRACTS__FETCH, async (_: never, thunkApi) => {
  // Set the loading state to true - we can use this state to show a spinner or disable the button
  thunkApi.dispatch(leasingContractsSetLoadingAction(true));
  // Fire the request and wait for the response
  const response = await getLeasingContractsRequest();

  // Soft validation on the response, so we don't populate the store with invalid data
  if (!response) {
    thunkApi.dispatch(leasingContractsSetErrorAction(true));
  } else {
    thunkApi.dispatch(leasingContractsSetAction(response));
  }

  // Set the loading state to false
  thunkApi.dispatch(leasingContractsSetLoadingAction(false));
});
