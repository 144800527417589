import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './reducers/auth-reducer';
import driverFilesReducer from './reducers/driver-files-reducer';
import drawerReducer from './reducers/drawer-reducer';
import profileReducer from './reducers/profile-reducer';
import leasingContractsReducer from './reducers/leasing-contracts-reducer';
import firstRendersReducer from './reducers/first-renders-reducer';
import repeatRendersReducer from './reducers/repeat-renders-reducer';
import emailBouncesReducer from './reducers/email-bounces-reducer';
import leasingCompaniesReducer from './reducers/leasing-companies-reducer';
import clientEmailSendingsReducer from './reducers/client-email-sendings-reducer';
import fleetManagerEmailSendingsReducer from './reducers/fleet-manager-email-sendings-reducer';

/**
 * Register application wide reducers here
 * Reducers will be bind to the root state using the namespace given
 * in the object passed to the combineReducers function
 */
const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  drawer: drawerReducer,
  driverFiles: driverFilesReducer,
  leasingContracts: leasingContractsReducer,
  firstRenders: firstRendersReducer,
  repeatRenders: repeatRendersReducer,
  emailBounces: emailBouncesReducer,
  leasingCompanies: leasingCompaniesReducer,
  clientEmailSendings: clientEmailSendingsReducer,
  fleetManagerEmailSendings: fleetManagerEmailSendingsReducer,
});

export default rootReducer;
