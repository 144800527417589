import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90, hide: true },
  {
    field: 'leaseCompany',
    headerName: 'NaamLL ',
    width: 190,
  },
  {
    field: 'clientNumber',
    headerName: 'Klantnummer',
    width: 180,
  },
  {
    field: 'licensePlate',
    headerName: 'Kenteken',
    sortable: false,
    width: 170,
  },
  {
    field: 'chassisNumber',
    headerName: 'Chassisnummer',
    width: 200,
    sortable: false,
  },
  {
    field: 'firstName',
    headerName: 'Contractantvoornaam',
    width: 250,
  },
  {
    field: 'lastName',
    headerName: 'Contractantnaam',
    width: 220,
  },
  {
    field: 'address',
    headerName: 'Contractantadres',
    width: 200,
  },
  {
    field: 'houseNumber',
    headerName: 'Contractanthuisnummer',
    width: 250,
  },
  {
    field: 'houseNumberAddition',
    headerName: 'Contractanthuisnummertoevoeging',
    width: 270,
  },
  {
    field: 'zipCode',
    headerName: 'Contractantpostcode',
    width: 220,
  },
  {
    field: 'city',
    headerName: 'Contractantplaats',
    width: 200,
  },
  {
    field: 'vehicleType',
    headerName: 'Soortvoertunig',
    width: 180,
  },
  {
    field: 'brandDescription',
    headerName: 'Merkomschrijving',
    width: 200,
  },
  {
    field: 'modelDescription',
    headerName: 'Modelomschrijving',
    width: 200,
  },
  {
    field: 'leaseContractNumber',
    headerName: 'Leasecontractnr',
    width: 230,
  },
  {
    field: 'contractCommencementDate',
    headerName: 'Ingangdatumcontract',
    type: 'date',
    width: 280,
  },
  {
    field: 'insurancePolicyNumber',
    headerName: 'Verzekeringpolisnr',
    width: 240,
  },
  {
    field: 'insuranceAgent',
    headerName: 'Verzekeringsmijnaam',
    width: 220,
  },
  {
    field: 'driverLanguageCode',
    headerName: 'Taal bestuurder',
    width: 180,
  },
  {
    field: 'driverSalutation',
    headerName: 'Aanroep bestuurder',
    width: 220,
  },
  {
    field: 'driverFirstName',
    headerName: 'Voornaam Bestuurder',
    width: 220,
  },
  {
    field: 'driverLastName',
    headerName: 'Naam bestuurder',
    width: 220,
  },
  {
    field: 'driverEmail',
    headerName: 'Email bestuurder',
    width: 220,
  },
  {
    field: 'sendEmailToFleetManager',
    headerName: 'Mail naar fleetmanager',
    width: 220,
  },
  {
    field: 'fleetManagerLanguageCode',
    headerName: 'Taal fleetmanager',
    width: 180,
  },
  {
    field: 'fleetManagerSalutation',
    headerName: 'Aanspreektitel fleetmanager',
    width: 220,
  },
  {
    field: 'fleetManagerFirstName',
    headerName: 'Naam Fleetmanager',
    width: 220,
  },
  {
    field: 'fleetManagerLastName',
    headerName: 'Voornaam Fleetmanager',
    width: 220,
  },
  {
    field: 'fleetManagerEmail',
    headerName: 'Email fleetmanager',
    width: 220,
  },
];
