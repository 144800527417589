import { getApi } from 'api';
import axios, { AxiosResponse } from 'axios';
import ILeasingContractsResponse from 'types/responses/ILeasingContractsResponse';
import log from 'utils/log';
import ILeasingContract from '../../types/ILeasingContract';

export const getLeasingContractsRequest = async (): Promise<ILeasingContract[]> => {
  try {
    const response: AxiosResponse<ILeasingContractsResponse> = await getApi().get('api/leasingcontracts/get-all');
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};
