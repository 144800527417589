import { Box, Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { getCsvWithRendersRequest } from 'api/requests/renders-request';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { repeatRendersFetchAsyncAction } from 'store/actions/repeat-renders-actions';
import { repeatRendersLoadingSelector, repeatRendersSelector } from 'store/selectors/repeat-renders-selectors';
import { columns } from './constants';

const RepeatRenders = () => {
  const dispatch = useAppDispatch();
  const rows = useAppSelector(repeatRendersSelector);
  const loading = useAppSelector(repeatRendersLoadingSelector);
  useEffect(() => {
    dispatch(repeatRendersFetchAsyncAction());
  }, []);

  const renderTable = () => {
    return (
      <Box component="div" height="670px" width="100%" mb={2}>
        <DataGrid loading={loading} rows={rows} columns={columns} pageSize={10} disableSelectionOnClick disableColumnSelector />
      </Box>
    );
  };
  return (
    <Box component="div">
      <Box component="div" display="flex" justifyContent="space-between" mb={2}>
        <Box component="h2" mt={0}>
          Repeat Renders
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          component="span"
          onClick={() => {
            getCsvWithRendersRequest('Repeat');
          }}
        >
          Download Csv
        </Button>
      </Box>
      <Box component="div" height="670px" width="100%" mb={2}>
        {renderTable()}
      </Box>
    </Box>
  );
};

export default RepeatRenders;
