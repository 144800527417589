import store from 'store';
import { authStateSelector } from 'store/selectors/auth-selectors';
import loadApi from './load-api';
import loadApp from './load-app';
import loadAuth from './load-auth';
import loadConfig from './load-config';
import loadTranslations from './load-translations';

const loadInitial = async (): Promise<void> => {
  await loadConfig();
  await loadApi();
  await loadTranslations();
  await loadAuth();
  // if (authStateSelector(store.getState())) {
  //   await loadApp();
  // }
};

export default loadInitial;
