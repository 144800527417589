import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import IEmailBounces from 'types/IEmailBounces';
import log from 'utils/log';

export const getNumberOfBouncesRequest = async (): Promise<number> => {
  try {
    const response: AxiosResponse<number> = await getApi().get('api/emailbounces/get-total-bounces-number');
    return response.data;
  } catch (err) {
    log(err);
  }
  return null;
};

export const getAllBouncesRequest = async (): Promise<IEmailBounces[]> => {
  try {
    const response: AxiosResponse<IEmailBounces[]> = await getApi().get('api/emailbounces/get-all-bounces');
    return response.data;
  } catch (err) {
    log(err);
  }
  return null;
};
