import { createReducer } from '@reduxjs/toolkit';
import {
  leasingCompaniesSetAction,
  leasingCompaniesSetErrorAction,
  leasingCompaniesSetLoadingAction,
  leasingCompaniesSetUploadLoadingAction,
  leasingCompaniesSetUploadResponseAction,
} from 'store/actions/leasing-companies-actions';
import ILeasingCompanies from 'types/ILeasingCompanies';

type LeasingCompaniesState = {
  files: ILeasingCompanies[];
  loading: boolean;
  uploadLoading: boolean;
  error: boolean;
  uploadResponse: string;
};

const initialState: LeasingCompaniesState = {
  files: [],
  loading: false,
  uploadLoading: false,
  error: false,
  uploadResponse: '',
};

const leasingCompaniesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(leasingCompaniesSetAction, (state, action) => ({ ...state, files: action.payload }))
    .addCase(leasingCompaniesSetErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(leasingCompaniesSetLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(leasingCompaniesSetUploadLoadingAction, (state, action) => ({
      ...state,
      uploadLoading: action.payload,
    }))
    .addCase(leasingCompaniesSetUploadResponseAction, (state, action) => ({ ...state, uploadResponse: action.payload })),
);

export default leasingCompaniesReducer;
