import { createReducer } from '@reduxjs/toolkit';
import { setDrawerStatusAction } from 'store/actions/drawer-actions';

type DrawerState = {
  status: boolean;
};

const initialState: DrawerState = {
  status: true,
};

const drawerReducer = createReducer(initialState, (builder) => builder.addCase(setDrawerStatusAction, (state, action) => ({ ...state, status: action.payload })));

export default drawerReducer;
