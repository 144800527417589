import { GridColDef } from '@mui/x-data-grid';
import { renderCellExpand } from './renderCellExpand';

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90, hide: true },
  {
    field: 'name',
    headerName: 'File name',
    sortable: false,
    width: 250,
  },
  {
    field: 'uploadDate',
    headerName: 'Upload date',
    type: 'date',
    width: 190,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    sortable: false,
  },
  {
    field: 'error',
    headerName: 'Error message',
    minWidth: 400,
    sortable: false,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
