import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { DataGrid } from "@mui/x-data-grid";
import { createFleetManagerEmailSending } from "api/requests/email-sendings-requests";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from 'store';
import { fleetManagerEmailSendingsFetchAsyncAction } from "store/actions/fleet-manager-email-sendings-actions";
import { fleetMngrEmailSendingLoadingSelector, fleetMngrEmailSendingSelector } from "store/selectors/fleet-manager-email-sending-selectors";
import { fleetManagerSendingsGridColumns } from "./constants";

const FleetManagerEmailSending = () => {

    const dispatch = useAppDispatch();

    const fleetMngrSendingRows = useAppSelector(fleetMngrEmailSendingSelector);
    const fleetMngrSendingsLoading = useAppSelector(fleetMngrEmailSendingLoadingSelector);
    const [createSendingDialogOpen, setCreateSendingDialogOpen] = useState(false);
    const [disableSendingCreation, setDisableSendingCreation] = useState(false);

    useEffect(() => {
        dispatch(fleetManagerEmailSendingsFetchAsyncAction());
    }, [])

    const handleCreateSendingCancel = () => {
        setCreateSendingDialogOpen(false);
    }

    const handleCreateSendingOk = async () => {
        // Disable Create Button
        setDisableSendingCreation(true);

        // Create new sending
        await createFleetManagerEmailSending();

        // Refresh the table 
        dispatch(fleetManagerEmailSendingsFetchAsyncAction());

        // Close the dialog
        setCreateSendingDialogOpen(false);

        // Enable Create Button
        setDisableSendingCreation(false);
    }

    const renderSendingsTable = () => {
        return (
            <Box component="div" height="600px" width="100%" mb={2}>
                <DataGrid getRowId={(r) => r.id} loading={fleetMngrSendingsLoading} rows={fleetMngrSendingRows} columns={fleetManagerSendingsGridColumns} pageSize={10} disableSelectionOnClick disableColumnSelector autoHeight />
            </Box>
        );
    };

    return (
        <Box component="div">
            <Box component="div" display="flex" justifyContent="space-between" mb={2}>
                <Box component="h2" mt={0}>
                    Fleet Manager Email Sendings
                </Box>
                <Box component="div">
                    <Button
                        size="large"
                        color="secondary"
                        variant="contained"
                        component="span"
                        onClick={() => {
                            setCreateSendingDialogOpen(true);
                        }}
                    >
                        Create Fleet Manager Email Sending
                    </Button>
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        component="span"
                        onClick={() => {
                            dispatch(fleetManagerEmailSendingsFetchAsyncAction());
                        }}
                        style={{
                            marginLeft: "1em"
                        }}
                    >
                        Refresh
                    </Button>
                </Box>
            </Box>
            {renderSendingsTable()}
            <Dialog
                open={createSendingDialogOpen}
            >
                <DialogTitle id="client-new-sending-dialog-title">
                    Create Fleet Manager Email Sending
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="client-new-sending-dialog-description">
                        Are you sure you want to start a new Certificate Email Sending for ALL Fleet Managers in the database?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateSendingCancel}>Cancel</Button>
                    <Button disabled={disableSendingCreation} onClick={handleCreateSendingOk} autoFocus>
                        Create Fleet Manager Email Sending
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FleetManagerEmailSending;