import { createReducer } from '@reduxjs/toolkit';
import { driverFilesSetAction, driverFilesSetErrorAction, driverFilesSetLoadingAction, driverFilesSetUploadLoadingAction } from 'store/actions/driver-files-actions';
import IDriverFile from 'types/IDriverFile';

type DriverFilesState = {
  files: IDriverFile[];
  loading: boolean;
  uploadLoading: boolean;
  error: boolean;
};

const initialState: DriverFilesState = {
  files: [],
  loading: false,
  uploadLoading: false,
  error: false,
};

const driverFilesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(driverFilesSetAction, (state, action) => ({ ...state, files: action.payload }))
    .addCase(driverFilesSetErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(driverFilesSetLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(driverFilesSetUploadLoadingAction, (state, action) => ({
      ...state,
      uploadLoading: action.payload,
    })),
);

export default driverFilesReducer;
