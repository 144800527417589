import { createReducer } from '@reduxjs/toolkit';
import IProfile from 'types/IProfile';
import { setProfileAction } from '../actions/profile-actions';

type ProfileState = {
  data: IProfile;
};

const initialState: ProfileState = {
  data: null,
};

const profileReducer = createReducer(initialState, (builder) => builder.addCase(setProfileAction, (state, action) => ({ ...state, data: action.payload })));

export default profileReducer;
