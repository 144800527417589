import { createReducer } from '@reduxjs/toolkit';
import IEmailSending from '../../types/IEmailSending';
import { clientEmailSendingsSetAction, clientEmailSendingsSetErrorAction, clientEmailSendingsSetLoadingAction } from '../actions/client-email-sendings-actions';

type ClientEmailSendingsState = {
    clientEmailSendings: IEmailSending[];
    loading: boolean;
    error: boolean;
};

const initialState: ClientEmailSendingsState = {
    clientEmailSendings: [],
    loading: false,
    error: false,
};

const clientEmailSendingsReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(clientEmailSendingsSetAction, (state, action) => ({ ...state, clientEmailSendings: action.payload }))
        .addCase(clientEmailSendingsSetErrorAction, (state, action) => ({ ...state, error: action.payload }))
        .addCase(clientEmailSendingsSetLoadingAction, (state, action) => ({ ...state, loading: action.payload }))
);

export default clientEmailSendingsReducer;