import { getApi } from 'api';
import axios, { AxiosResponse } from 'axios';
import ILeasingCompanies from 'types/ILeasingCompanies';
import ILeasingCompaniesResponse from 'types/responses/ILeasingCompaniesResponse';
import log from 'utils/log';

export const postLeasingCompaniesRequest = async (data: File): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append('file', data);
    const response: AxiosResponse<string> = await getApi().post('api/leasingcompanies/upload', formData);
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};

export const getLeasingCompaniesRequest = async (): Promise<ILeasingCompanies[]> => {
  try {
    const response: AxiosResponse<ILeasingCompaniesResponse> = await getApi().get('api/leasingcompanies/display');
    return response.data;
  } catch (err) {
    if (!axios.isAxiosError(err)) {
      log(err);
    }
  }
  return null;
};
