import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import { createClientEmailSending } from "api/requests/email-sendings-requests";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from 'store';
import { clientEmailSendingsFetchAsyncAction } from "store/actions/client-email-sendings-actions";
import { clientEmailSendingsLoadingSelector, clientEmailSendingsSelector } from "store/selectors/client-email-sendings-selectors";
import { clientSendingsGridColumns } from "./constants";

const ClientEmailSeding = () => {

    const dispatch = useAppDispatch();
    const clientSendingsRows = useAppSelector(clientEmailSendingsSelector);
    const clientSendingsLoading = useAppSelector(clientEmailSendingsLoadingSelector);
    const [createClientSendingDialogOpen, setCreateClientSendingDialogOpen] = useState(false);
    const [disableSendingCreation, setDisableSendingCreation] = useState(false);

    const handleCreateClientSendingOk = async () => {
        // Disable Create Button
        setDisableSendingCreation(true);

        // Create new sending
        await createClientEmailSending();

        // Refresh the table
        dispatch(clientEmailSendingsFetchAsyncAction());

        // Close the dialog
        setCreateClientSendingDialogOpen(false);

        // Enable Create Button
        setDisableSendingCreation(false);
    };

    const handleCreateClientSendingCancel = () => {
        // Close the dialog
        setCreateClientSendingDialogOpen(false);
    };

    useEffect(() => {
        dispatch(clientEmailSendingsFetchAsyncAction());
    }, []);

    const renderClientSendingsTable = () => {
        return (
            <Box component="div" height="600px" width="100%" mb={2}>
                <DataGrid getRowId={(r) => r.id} loading={clientSendingsLoading} rows={clientSendingsRows} columns={clientSendingsGridColumns} pageSize={10} disableSelectionOnClick disableColumnSelector autoHeight/>
            </Box>
        );
    };

    return (
        <Box component="div">
            <Box component="div" display="flex" justifyContent="space-between" mb={2}>
                <Box component="h2" mt={0}>
                    Client Email Sendings
                </Box>
                <Box component="div">
                    <Button
                        size="large"
                        color="secondary"
                        variant="contained"
                        component="span"
                        onClick={() => {
                            setCreateClientSendingDialogOpen(true);
                        }}
                    >
                        Create Client Email Sending
                    </Button>
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        component="span"
                        onClick={() => {
                            dispatch(clientEmailSendingsFetchAsyncAction());
                        }}
                        style={{
                            marginLeft: "1em"
                        }}
                    >
                        Refresh
                    </Button>
                </Box>
            </Box>
            {renderClientSendingsTable()}
            <Dialog
                open={createClientSendingDialogOpen}
            >
                <DialogTitle id="client-new-sending-dialog-title">
                    Create Client Email Sending
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="client-new-sending-dialog-description">
                        Are you sure you want to start a new Certificate Email Sending for ALL Clients in the database?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateClientSendingCancel}>Cancel</Button>
                    <Button disabled={disableSendingCreation} onClick={handleCreateClientSendingOk} autoFocus>
                        Create Client Email Sending
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ClientEmailSeding;
