import { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import Router from 'router';
import loadInitial from 'services/load-initial';
import theme from './theme';

function App() {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    (async () => {
      await loadInitial();
      setIsReady(true);
    })();
  }, []);

  if (!isReady) {
    // Here you can also return a loading screen, while your app is bootstraping
    return null;
  }

  /**
   * You can wrap your component with providers that require store access
   * or other dependencies loaded in the previous step (index.ts)
   */
  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
