export const AUTH__SET_TOKEN = '[AUTH] SET_TOKEN';
export const AUTH__SET_LOADING = '[AUTH] SET_LOADING';
export const AUTH__FETCH_LOGIN = '[AUTH] FETCH_LOGIN';
export const AUTH__FETCH_LOGOUT = '[AUTH] FETCH_LOGOUT';
export const AUTH__SET_ERROR = '[AUTH] SET_ERROR';

export const PROFILE__SET = '[PROFILE] SET';
export const PROFILE__FETCH = '[PROFILE] FETCH';

export const DRAWER__SET_STATUS = '[DRAWER] SET_STATUS';

export const DRIVER_FILES__SET = '[DRIVER_FILES] SET';
export const DRIVER_FILES__SET_ERROR = '[DRIVER_FILES] SET_ERROR';
export const DRIVER_FILES__SET_LOADING = '[DRIVER_FILES] SET_LOADING';
export const DRIVER_FILES__FETCH = '[DRIVER_FILES] FETCH';
export const DRIVER_FILES__CREATE = '[DRIVER_FILES] CREATE';
export const DRIVER_FILES__SET_UPLOAD_LOADING = '[DRIVER_FILES] SET_UPLOAD_LOADING';

export const LEASING_CONTRACTS__SET = '[LEASING_CONTRACTS] SET';
export const LEASING_CONTRACTS__SET_LOADING = '[LEASING_CONTRACTS] SET_LOADING';
export const LEASING_CONTRACTS__SET_ERROR = '[LEASING_CONTRACTS] SET_ERROR';
export const LEASING_CONTRACTS__FETCH = '[LEASING_CONTRACTS] FETCH';

export const FIRST_RENDERS__SET = '[FIRST_RENDERS] SET';
export const FIRST_RENDERS__SET_LOADING = '[FIRST_RENDERS] SET_LOADING';
export const FIRST_RENDERS__SET_ERROR = '[FIRST_RENDERS] SET_ERROR';
export const FIRST_RENDERS__FETCH = '[FIRST_RENDERS] FETCH';

export const REPEAT_RENDERS__SET = '[REPEAT_RENDERS] SET';
export const REPEAT_RENDERS__SET_LOADING = '[REPEAT_RENDERS] SET_LOADING';
export const REPEAT_RENDERS__SET_ERROR = '[REPEAT_RENDERS] SET_ERROR';
export const REPEAT_RENDERS__FETCH = '[REPEAT_RENDERS] FETCH';

export const EMAIL_BOUNCES__SET = '[EMAIL_BOUNCES] SET';
export const EMAIL_BOUNCES__SET_LOADING = '[EMAIL_BOUNCES] SET_LOADING';
export const EMAIL_BOUNCES__SET_ERROR = '[EMAIL_BOUNCES] SET_ERROR';
export const EMAIL_BOUNCES__FETCH = '[EMAIL_BOUNCES] FETCH';

export const LEASING_COMPANIES__SET = '[LEASING_COMPANIES] SET';
export const LEASING_COMPANIES__SET_ERROR = '[LEASING_COMPANIES] SET_ERROR';
export const LEASING_COMPANIES__SET_LOADING = '[LEASING_COMPANIES] SET_LOADING';
export const LEASING_COMPANIES__FETCH = '[LEASING_COMPANIES] FETCH';
export const LEASING_COMPANIES__CREATE = '[LEASING_COMPANIES] CREATE';
export const LEASING_COMPANIES__SET_UPLOAD_LOADING = '[LEASING_COMPANIES] SET_UPLOAD_LOADING';
export const LEASING_COMPANIES__SET_UPLOAD_RESPONSE = '[LEASING_COMPANIES] SET_UPLOAD_RESPONSE';

export const CLIENT_EMAIL_SENDINGS__FETCH = '[CLIENT_EMAIL_SENDINGS] FETCH';
export const CLIENT_EMAIL_SENDINGS__SET_LOADING = '[CLIENT_EMAIL_SENDINGS] SET_LOADING';
export const CLIENT_EMAIL_SENDINGS__SET_ERROR = '[CLIENT_EMAIL_SENDINGS] SET_ERROR';
export const CLIENT_EMAIL_SENDINGS__SET = '[CLIENT_EMAIL_SENDINGS] SET';

export const FLEET_MANAGER_EMAIL_SENDINGS__FETCH = '[FLEET_MANAGER_EMAIL_SENDING] FETCH';
export const FLEET_MANAGER_EMAIL_SENDINGS__SET_LOADING = '[FLEET_MANAGER_EMAIL_SENDING] SET_LOADING';
export const FLEET_MANAGER_EMAIL_SENDINGS__SET_ERROR = '[FLEET_MANAGER_EMAIL_SENDING] SET_ERROR';
export const FLEET_MANAGER_EMAIL_SENDINGS__SET = '[FLEET_MANAGER_EMAIL_SENDING] SET';