import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import store from 'store';
import { CookiesProvider } from 'react-cookie';
import Application from './Application';

ReactDOM.render(
  <CookiesProvider>
    <React.StrictMode>
      <Provider store={store}>
        <Application />
      </Provider>
    </React.StrictMode>
  </CookiesProvider>,
  document.getElementById('root'),
);
