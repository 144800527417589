import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useAppDispatch, useAppSelector } from 'store';
import { setDrawerStatusAction } from 'store/actions/drawer-actions';
import { drawerStatusSelector } from 'store/selectors/drawer-selectors';
import { useHistory } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';
import { MenuItem } from '@material-ui/core';
import { useState, SyntheticEvent } from 'react';
import DvrOutlinedIcon from '@material-ui/icons/DvrOutlined';
import { authTokenSelector } from 'store/selectors/auth-selectors';
import { useCookies } from 'react-cookie';
import { getConfig } from 'config';
import { menuItems, menuItemsSecondary } from './constants';
import useStyles from './styles';
import { AppContainerProps } from './types';

const AppContainer = (props: AppContainerProps) => {
  const { children } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const drawerStatus = useAppSelector(drawerStatusSelector);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const token = useAppSelector(authTokenSelector);
  const [, setCookie] = useCookies(['HangFireCookie']);

  const handleMenu = (event: SyntheticEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (route: string) => {
    setAnchorEl(null);
    history.push(route);
  };

  const setHangfireCookie = () => {
    setCookie('HangFireCookie', token, {
      path: '/',
    });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerStatus,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(setDrawerStatusAction(true))}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerStatus,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            VanMosselICG Admin
          </Typography>
          <div>
            <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleClose('/profile')}>Profile</MenuItem>
              <MenuItem onClick={() => handleClose('/logout')}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerStatus,
          [classes.drawerClose]: !drawerStatus,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerStatus,
            [classes.drawerClose]: !drawerStatus,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => dispatch(setDrawerStatusAction(false))}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <ListItem button key={item.name} onClick={() => history.push(item.route)}>
              <ListItemIcon>{item.icon()}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
          <ListItem
            button
            key="Hangfire"
            onClick={() => {
              setHangfireCookie();
              window.open(`${getConfig('API_BASE_URL')}hangfire`, '_blank');
            }}
          >
            <ListItemIcon>
              <DvrOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Hangfire" />
          </ListItem>
        </List>
        <Divider />
        <List>
          {menuItemsSecondary.map((item) => (
            <ListItem button key={item.name} onClick={() => history.push(item.route)}>
              <ListItemIcon>{item.icon()}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default AppContainer;
